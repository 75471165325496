function lazyObserverController(elementId) {
  let elm = document.getElementById(elementId);
  if (!elm) return;
  let action = document.getElementById(elementId).getAttribute('data-action')
  let finished = document.getElementById(elementId).getAttribute('data-finished')
  const lazyObserver = new IntersectionObserver(
    function(entries) {
     entries.forEach(function(entry) {
       if (entry.isIntersecting && !finished) {
          $.get(action, {target: elementId});
          elm.setAttribute('data-finished', true);
       }
     })
    }
  );
  
  lazyObserver.observe(elm);
}

export default { init: lazyObserverController }
