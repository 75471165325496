import Swiper from 'swiper'

function controllerSliders() {
    const init = function() {
        $('.pricing_table').scrollLeft(1000);
        setTimeout(function(){
            $('.pricing_table').animate({ 'scrollLeft' : 0 },2000);
        },1000);

        var bookSlider = [];
        $('.home_book_slider').each(function () {

            var index = $(this).index();

            $(this).find('.home_book_slider-pagination-prev').addClass('book_slider_' + index);
            $(this).find('.home_book_slider-pagination-bullets').addClass('book_slider_' + index);
            $(this).find('.home_book_slider-pagination-next').addClass('book_slider_' + index);
            $(this).find('.home_book_slider-slider').addClass('book_slider_' + index);
            
            bookSlider[index] = new Swiper ('.home_book_slider-slider.book_slider_' + index , {
                loop: false,
                speed: 1000,
                spaceBetween: 32,
                slidesPerView: 3.5,
                slidesPerGroup: 3,
                pagination: {
                    el: '.home_book_slider-pagination-bullets.book_slider_' + index,
                    clickable: true,
                },
                navigation: {
                    nextEl: '.home_book_slider-pagination-next.book_slider_' + index,
                    prevEl: '.home_book_slider-pagination-prev.book_slider_' + index,
                },
                breakpoints:{
                    1199 : {
                        slidesPerView: 2.5,
                        slidesPerGroup: 2,
                    },
                    991 : {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                    767 : {
                        slidesPerView: 1.5,
                        spaceBetween: 16,
                        slidesPerGroup: 1,
                    }
                }
            });

        });
    }

    return { init: init }
}

export default controllerSliders()