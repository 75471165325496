function controllerCommon() {
    const init = function() {
        $('body').on('click', '.header-categories', toggleCategories);
        $('body').on('mouseleave', '.header', hideCategories);
        $('body').on('click', '.template_content-video-play', playVideo);
        $('body').on('click', '.template_content-accordion-item-question', toggleAccordion);
        $('body').on('click', '.menu_mobile-top-menu_button', toggleMobileMenu);
        $('body').on('click', '.speak_to_author-box-close,.speak_to_author_overlay', closeSpeakToAuthor);
        $('body').on('click', '.book_single_author-links .chat', openSpeakToAuthor);
        $('body').on('click', '.alert .close', closeAlert);
        
        //HIDE CPF CNPJ
        $('#country').change(function(){
            if( $(this).val() != 'Brazil' ){
                $('.hide_if_other_country').slideUp();
            }else{
                $('.hide_if_other_country').slideDown();
            }
        });

        //FIXED HEADER
        $(window).scroll(function(){
            if( $(window).scrollTop() > 20 ){
                $('.header').addClass('fixed');
            }else{
                $('.header').removeClass('fixed');
            }
        });

        //ACCORDION
        $(window).scroll(function () {
            $('.template_content-accordion:not(.auto-disabled)').each(function () {
                if (!$(this).hasClass('opened')) {
                    if ($(this).offset().top < $(window).scrollTop() + $(window).height() / 2) {
                        $(this).addClass('opened');
                        $(this).find('.template_content-accordion-item:first-child').find('.template_content-accordion-item-question').click();
                    }
                }
            });

            if ($(window).scrollTop() > 60) {
                $('.menu_mobile-top').addClass('small');
            } else {
                $('.menu_mobile-top').removeClass('small');
            }
        });

        // Vai ajustar o css das mensagens de erro ou sucesso dinamicamente
        $(window).on('load', adjustMessageElement);
        $(window).on('resize', adjustMessageElement);
    }

    
    //FUNCTIONS
    const toggleCategories = function() {
        $('.header-categories-drop').slideToggle();
    }
    const hideCategories = function() {
        $('.header-categories-drop').slideUp();
    }
    const playVideo = function() {
        $(this).fadeOut();
        $(this).prev().attr('src', $(this).data('url'));
    }
    const toggleAccordion = function() {
        if ($(this).parent().hasClass('active')) {
            $(this).parent().removeClass('active');
            $(this).next().slideUp();
        } else {
            $(this).closest('.template_content-accordion').find('.template_content-accordion-item').removeClass('active').find('.template_content-accordion-item-answer').slideUp();
            $(this).parent().addClass('active');
            $(this).next().slideDown();
        }
    }
    const toggleMobileMenu = function() {
        $(this).toggleClass('active');
        $('.menu_mobile-top-drop').slideToggle();
    }

    const openSpeakToAuthor = function() {
        $('.speak_to_author_overlay').fadeIn();
        $('.speak_to_author').slideDown();
        return false;
    }
    const closeAlert = function(e) {
        e.preventDefault();
        $(this).parent().slideUp();

        return false;
    }
    const closeSpeakToAuthor = function() {
        $('.speak_to_author_overlay').fadeOut();
        $('.speak_to_author').slideUp();
    }

    const adjustMessageElement = function() {
        var messageErrorsElement = document.querySelectorAll("#message");
        var sectionBannerMobile = document.querySelector('.home_banner-mobile');

        if (sectionBannerMobile == null) {
          return;
        }

        if (messageErrorsElement.length >= 1) {
            messageErrorsElement.forEach((element, i) => {
                if (window.innerWidth < 767) {
                    if (i == 0) {
                        element.style.marginTop = '10px';
                        return;
                    }

                    element.style.paddingTop = '0';
                    element.children[0].style.margin = '0';
                }
            });
        } else {
            sectionBannerMobile.style.marginTop = '24px';
        }
    }

    return {
        init: init
    }
}

export default controllerCommon()
